import React, { Suspense } from 'react';
import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginService } from "./services/LoginService";
const PhotoUploadPage = React.lazy(() => import('./containers/PhotoUploadPage/PhotoUploadPage'));
const Login = React.lazy(() => import('./containers/Login/Login'));
const CreateAccount = React.lazy(() => import('./containers/CreateAccount/CreateAccount'));
const PhotosList = React.lazy(() => import('./containers/PhotosList/PhotosList'));
const AdminStart = React.lazy(() => import('./containers/AdminStart/AdminStart'));
const Settings = React.lazy(() => import('./containers/Settings/Settings'));
const GenerateQR = React.lazy(() => import('./containers/GenerateQR/GenerateQR'));
const Homepage = React.lazy(() => import('./containers/Homepage/Homepage'));
const CreateAccount2 = React.lazy(() => import('./containers/CreateAccount/CreateAccount2'));
const Events = React.lazy(() => import('./containers/Events/Events'));
const MyEvent = React.lazy(() => import('./containers/MyEvent/MyEvent'));
const PaymentSuccess = React.lazy(() => import('./containers/PaymentSuccess/PaymentSuccess'));
const PaymentRejected = React.lazy(() => import('./containers/PaymentRejected/PaymentRejected'));
const SupremeAdmin = React.lazy(() => import('./containers/SupremeAdmin/SupremeAdmin'));
const ClientPage = React.lazy(() => import('./containers/SupremeAdmin/ClientPage'));
const VideosList = React.lazy(() => import('./containers/VideosList/VideosList'));
const ResetPassword = React.lazy(() => import('./containers/ResetPassword/ResetPassword'));
const PublicGallery = React.lazy(() => import('./containers/PublicGallery/PublicGallery'));
const Blog = React.lazy(() => import('./containers/Blog/Blog'));
const Article = React.lazy(() => import('./containers/Article/Article'));
const PublicGalleryVideos = React.lazy(() => import('./containers/PublicGalleryVideos/PublicGalleryVideos'));

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Raleway'
          }
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Routes>
              <Route exact path="/:slug" element={<PhotoUploadPage />} />
              <Route exact path="/:slug/public_galleries/:token" element={<PublicGallery />} />
              <Route exact path="/:slug/public_galleries/:token/videos" element={<PublicGalleryVideos />} />
              <Route exact path="/app/login" element={<Login />} />
              <Route exact path="/" element={<Homepage />} />
              <Route exact path="/app/creeaza-cont" element={<CreateAccount />} />
              <Route exact path="/app/creeaza-cont-parola" element={<CreateAccount2 />} />
              <Route exact path="/app/setari-profil" element={<Settings />} />
              <Route exact path="/app/genereaza-cod" element={<GenerateQR />} />
              <Route exact path="/app/poze-eveniment" element={<PhotosList />} />
              <Route exact path="/app/videouri-eveniment" element={<VideosList />} />
              <Route exact path="/app/start" element={<AdminStart />} />
              <Route exact path="/app/plata-succes" element={<PaymentSuccess />} />
              <Route exact path="/app/plata-esuata" element={<PaymentRejected />} />
              <Route exact path="/app/evenimentul-meu" element={<MyEvent />} />
              <Route exact path="/app/reseteaza-parola" element={<ResetPassword />} />
              <Route exact path="/app/clienti" element={<AdminRoute><SupremeAdmin /></AdminRoute>} />
              <Route exact path="/app/evenimente" element={<LoggedRoute><Login /></LoggedRoute>} />
              <Route exact path="/app/client/:id" element={<ClientPage/>} />
              <Route exact path="/app/blog" element={<Blog/>} />
              <Route exact path="/app/article/:id" element={<Article/>} />

            </Routes>
          </Router>
          <ToastContainer autoClose={1000}   position="bottom-center" />
        </Suspense>
      </ConfigProvider>
    </div>
  );
}

const LoggedRoute = ({ children }: { children: JSX.Element }) => {
  return loginService.loggedIn() ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
    />
  )
};

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  return loginService.loggedIn() ? (
    loginService.isAdmin() ? (
      children
    ) : (
      <Navigate
        to={{
          pathname: '/app/start',
        }}
      />
    )
  ) : (
    <Navigate
      to={{
        pathname: '/app/login',
      }}
    />
  )
};

export default App;
